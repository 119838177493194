


















































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { remoteServiceBaseUrl } from "@/libs/appconst";

@Component
export default class Feedbacks extends Vue {
  pagerHeader: any = {
    title: "问题反馈",
    desc: "问题反馈",
    breadcrumb: ["会员管理", "问题反馈"],
  };
  loading: boolean = false;
  filter: any = {
    page: 1,
    size: 10,
    type: null, //订单问题
    keywords: "",
    startDate: null,
    endDate: null,
    status: 0,
  };
  dateRange: any = [];
  tableData: any = {
    items: [],
    totalCount: 0,
  };
  handleDialog: any = {
    show: false,
    form: {
      id: "",
      remark: "",
      isHandled: true,
      replyImages: [],
    },
    rules: {
      remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
    },
    loading: false,
  };
  dialogImageUrl:any='';
  dialogVisible:any=false
  //日期改变
  timeVoi(value: Date[]) {
    if (value != null && value.length == 2) {
      this.filter.startDate = value[0].Format("yyyy-MM-dd hh:mm:ss");
      if (
        value[1].Format("hh") == "00" &&
        value[1].Format("mm") == "00" &&
        value[1].Format("ss") == "00"
      ) {
        this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59");
        this.dateRange = [
          (this.filter.startDate = value[0].Format("yyyy-MM-dd hh:mm:ss")),
          (this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59")),
        ];
      } else {
        this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss");
        this.dateRange = [
          (this.filter.startDate = value[0].Format("yyyy-MM-dd hh:mm:ss")),
          (this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")),
        ];
      }
      this.readerPage();
    } else {
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.readerPage();
    }
  }
  imgFuc(url: string) {
    window.open(url);
  }
  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.readerPage();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.readerPage();
  }
  async readerPage() {
    this.loading = true;
    try {
      let res = await this.$ajax.get(
        "/api/services/app/Feedback/GetPagedList",
        {
          params: {
            ...this.filter,
          },
        }
      );

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  search() {
    this.filter.page = 1;
    this.readerPage();
  }
  activated() {
    this.readerPage();
  }
  created() {
    this.readerPage();
  }
  get statusOptions() {
    // return [];
    return this.getEnum("FeedbackStatus");
  }
  get questionStatus() {
    return [
      {
        value: "1",
        name: "设备故障",
      },
      {
        value: "2",
        name: "订单问题",
      },
      {
        value: "3",
        name: "其他",
      },
    ];
  }
  async handleRecord() {
    (this.$refs.handleForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.handleDialog.loading = true;
        let arr:any=[]
        this.handleDialog.form.replyImages.forEach((element:any) => {
          arr.push(element.url)
        });
        try {
          var res = await this.$ajax.post(
            "/api/services/app/Feedback/Handle",
            {
              id: this.handleDialog.form.id,
              remark:this.handleDialog.form.remark,
              isHandled:this.handleDialog.form.isHandled,
              replyImages:arr
            }
          );
          if (res.data.success) {
            var data = res.data.result;
            this.$notify.success(`处理成功`);
            this.readerPage();

            this.handleDialog.show = false;
          }
        } catch (error) {}
        this.handleDialog.loading = false;
      }
    });
  }
  openHandleDialog(id: number) {
    this.handleDialog.show = true;
    this.handleDialog.form.id = id;
    this.handleDialog.form.remark = "";
    this.handleDialog.form.isHandled = true;
    this.handleDialog.form.replyImages = [];
  }

  
// 上传图片和删除图片和放大图片
  handleRemove(file:any, fileList:any) {
        // console.log(file);
        //   console.log(fileList);
        // var pick:any=[]
        // fileList.forEach((element:any) => {
        //   pick.push({name:element.name,url:element.response.result.fileUrl}) 
        // });
        this.handleDialog.form.replyImages=fileList
      }
  handlePictureCardPreview(file:any) {
    this.dialogImageUrl = file.url;
    this.dialogVisible = true;
  }
  relyhandleAvatarSuccess(res:any, file:any) {  
      this.handleDialog.form.replyImages = [
        ...this.handleDialog.form.replyImages,
        { name: file.name, url: res.result.fileUrl }
      ];
  }
  handfail(err:any, file:any, fileList:any){
     this.$notify.warning(`上传失败`);
  }
  
}
